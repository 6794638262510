$(window).ready(function(){

    $(".subscribe-link a").click(function(e){
        e.preventDefault();
        var token = $('meta[name=_token]').attr('content');
        var href = $(this).attr('data-href');
        var form = `
            <form class="custom-form" id="subscribe_popup_form">
                <input type="hidden" data-validation="required email" name="_token" value="${token}"/>
                <div class="form-group">
                    
                        ${Language.email}
                    
                    <input type="text" class="form-control" id="subscribe-email" name="email"/>
                </div>
                <div class="form-group">
                    <label for="news_input">
                        <input type="checkbox" id="news_input" name="news" value="1"/>
                        <label style="vertical-align:middle;width:17.5px;height:17.5px;margin-left:10px;" for="news_input"></label>
                        
                            ${Language.subscribe_news}
                        
                    </label>
                </div>
                <div class="form-group">
                    <label for="calls_input">
                        <input type="checkbox" id="calls_input" name="calls" value="1"/>
                        <label style="vertical-align:middle;width:17.5px;height:17.5px;margin-left:10px;" for="calls_input"></label>
                        
                            ${Language.subscribe_calls}
                        
                    </label>
                </div>

                <div class="form-group">
                    <label for="expert_calls_input">
                        <input type="checkbox" id="expert_calls_input" name="expert_calls" value="1"/>
                        <label style="vertical-align:middle;width:17.5px;height:17.5px;margin-left:10px;" for="expert_calls_input"></label>
                        
                            ${Language.subscribe_expert_calls}
                        
                    </label>
                </div>
                <div class="form-group">
                    <label for="events_input">
                        <input type="checkbox" id="events_input" name="events" value="1"/>
                        <label style="vertical-align:middle;width:17.5px;height:17.5px;margin-left:10px;" for="events_input"></label>
                        
                            ${Language.subscribe_events}
                        
                    </label>
                </div>
            </form>
        `;
 
        var modal = bootbox.dialog({
            message: form,
            title: Language.subscribe,
            buttons: [
            {
                label: Language.subscribe,
                className: "btn btn-primary subscribe-btn pull-left subscribe-btn-remove",
                callback: function() {
                    // console.log(modal.find('form').serialize());
                    $.post(href, modal.find('form').serialize(), function(data){
                        $("#subscribe_popup_form").before(
                            $(`<div class="alert alert-success">${data.message}</div>`)).remove();
                        // console.log(data);
                        $(".subscribe-btn-remove").hide();
                    }).fail(function(data) {
                        $("#subscribe_popup_form").find('.alert').remove();
                        var errors = data.responseJSON;
                        var li = ``;
                        if(errors.email) {
                            li = `${li} <li>${errors.email[0]}</li>`
                        }

                        if(errors.news) {
                            li = `${li} <li>${errors.news[0]}</li>`
                        }

                        $("#subscribe_popup_form").prepend(`<div class="alert alert-danger"><ul>${li}</ul></div>`);
                        // console.log(data.responseJSON);
                      });

                    return false;
                }
            },
            // {
            //     label: Language.close,
            //     className: "btn btn-default pull-left",
            //     callback: function() {
            //         console.log("just do something on close");
            //     }
            // }
            ],
            show: false,
            onEscape: function() {
                modal.modal("hide");
            }
        });

        modal.modal("show");
    });

    $("#subscribe-btn").click(function(){
        $(".subscribe-link a").click();
        $("#subscribe-email").val($("#subscribe-field").val());
    });

});